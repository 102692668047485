import React from "react";
import cn from "classnames";
import styles from "./News.module.sass";
import Slider from "react-slick";
import Icon from "../Icon";
import Card from "./Card";

const items = [
  {
    title: "Our Valued Partners",
    content:
      "Bitdenex collaborates with highly qualified and experienced partners who provide the best solutions to the end-user, means our Customers. We aim to select the best in class providers for example; your Know Your Customer process, Custodian Crypto Wallets, Wallet AML Risk reports, Global Banking, Safe & Secure IT-environment, Secured Back-ups, Legitimate emails, Charting and many more.",
    stage: "Partners",
    image: "/images/content/partners.png",
    image2x: "/images/content/partners@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
    buttonText: "Start Trading"
  },
  {
    title: "The Bitdenex Cryptocurrency Exchange",
    content:
      "Bitdenex is an exchange and trading platform where you can trade your cryptocurrency like Bitcoin, Ethereum, Litecoin, XRP and many more high valued cryptocurrencies.",
    stage: "About Bitdenex",
    image: "/images/content/news-pic-1.png",
    image2x: "/images/content/news-pic-1@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
    buttonText: "Start Trading"
  },
  {
    title: "Listing only reliable and valuable Coins",
    content:
      "Only high valued cryptocurrencies. We will only list the most reliable and the most valuable coins and tokens. From time to time, we will poll our user’s need to list special coins or tokens. We will not mis the opportunity to list a high potential coin or token. ",
    stage: "Listings",
    image: "/images/content/lock.png",
    image2x: "/images/content/lock@2x.png",
    url: localStorage.getItem("signature") ? "/market" : "/sign-up",
    buttonText: "View Coins"
  },
  {
    title: "Buy and Sell Cryptocurrencies On The Go",
    content:
      "Nowadays people spend more and more time on their mobile devices and tablets. To ensure that visitors who access Bitdenex from mobile devices have a great experience on the device. Apart from optimizing the web view, Bitdenex is soon launching the mobile apps for Android and IOS users to unlock the power of better connection with people.",
    stage: "Mobile Apps",
    image: "/images/content/card-pic.png",
    image2x: "/images/content/card-pic@2x.png",
    url: localStorage.getItem("signature") ? "/buy-crypto" : "/sign-up",
    buttonText: "Buy Now"
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const News = ({ classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="18" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="18" />
      </SlickArrow>
    ),
  };

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          {/* <div className={styles.logo}>
            <Image
              className={styles.pic}
              src="/images/logo-light.svg"
              srcdark="/images/logo-dark.svg"
              alt="BitCloud"
            />
          </div> */}
          <h2 className={cn("h3", styles.title)}>
            Stay in the know on crypto with <span>Bitdenex</span>
          </h2>
          <div className={styles.info}>
            A creative exchange that lead and inspire
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className="news-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default News;
