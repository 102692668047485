import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import { Link } from "react-router-dom";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";

const Form = ({ goNext, resetPasswordOtpGenerateHandler, resetPasswordOtpLoading }) => {
  // initializing the states and variables 
  const [email, setEmail] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  return (
    <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
      <div className={styles.top}>
        <h3 className={cn("h3", styles.title)}>Forgot password</h3>
        <div className={styles.info}>
          For security purposes, no withdrawals are permitted for 24 hours after
          password is changed.
        </div>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Enter the account email"
          name="email"
          type="email"
          placeholder="Your email"
          icon="email"
          value={email}
          onChange={(e) => { setEmail(e.target.value); }}
          required
          onBlur={() => { simpleValidator.current.showMessageFor("email") }}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("email", email, "required|email")}
        </span>
      </div>
      <button disabled={resetPasswordOtpLoading} className={cn("button", styles.button)}
        onClick={
          (e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              resetPasswordOtpGenerateHandler(email);
            }
            else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            };
          }
        }>
        Continue
      </button>
      <div className={styles.foot}>
        <Link className={styles.link} to="/sign-in">
          Nevermind, I got it
        </Link>
      </div>
    </form>
  );
};

export default Form;
