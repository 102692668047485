import React from "react";
import cn from "classnames";
import styles from "./Popular.module.sass";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Card from "./Card";

const items = [
  {
    title: "Sell with low trading fee",
    content: "To pay instantly, simply type or paste your recipient’s Customer ID.",
    button: "Get Started",
    image: "/images/content/partners/card-pic-1.png",
    image2x: "/images/content/partners/card-pic-1@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
  },
  {
    title: "Crypto customers for your business",
    content: "With Bitdenex Pay, to pay someone via QR code, simply scan their Bitdenex Pay QR code with your Bitdenex app.",
    button: "Get Started",
    image: "/images/content/partners/card-pic-2.png",
    image2x: "/images/content/partners/card-pic-2@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
  },
  {
    title: "Get paid in your preferred cryptocurrency",
    content: "With Bitdenex Pay, to receive crypto, simply share your Bitdenex Pay QR code within your Bitdenex app.",
    button: "Get Started",
    image: "/images/content/partners/card-pic-3.png",
    image2x: "/images/content/partners/card-pic-3@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Popular = ({ classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
    ],
  };

  return (
    <div className={cn("section-bg section-mb0", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h3", styles.title)}>
          Why Bitdenex Pay?
          </h2>
          {/* <div className={styles.info}>
            Reasons why to trade and invest with us.
          </div> */}
        </div>
        <div className={styles.wrapper}>
          <Slider className="popular-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Popular;
