import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";
const items = [
  {
    title: "Profile",
    icon: "user",
    content: "Important account details",
    url: "/profile-info",
  },

  {
    title: "Kyc Verification",
    icon: "eye",
    content: "Submit your KYC",
    url: "/user-kyc-verification",
  },

  {
    title: "Bank Verification",
    icon: "wallet",
    content: "Get your bank account verified",
    url: "/bank-verification",
    separator: true,
  },

  // {
  //   title: "2FA security",
  //   icon: "lock",
  //   content: "Setup 2FA for more security",
  //   url: "/2fa",
  // },
  // {
  //   title: "Settings",
  //   icon: "cog",
  //   content: "View additional settings",
  //   url: "/profile-info",
  // },

  {
    title: "Dark mode",
    icon: "theme-dark",
    content: "Switch dark/light mode",
  },

];

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate()
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.user, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src="/images/content/avatar-user.jpg" alt="Avatar" />
        </button>
        <div className={styles.body}>
          <div className={styles.menu}>
            {items.map((x, index) =>
              x.url ? (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </Link>
              ) : (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.title}>{x.title}</div>
                      <Theme className={styles.theme} small />
                    </div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </div>
              )
            )}
            <button
              className={styles.item}
              onClick={() => {
                localStorage.clear();
                navigate('/sign-in')
                // window.location.href = "/sign-in";
                return false;
              }}
            >
              <div className={styles.icon}>
                <Icon name="exit" size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.title}>Logout</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
