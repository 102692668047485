import React from "react";
import { Link } from "react-router-dom";
import styles from "./Logo.module.sass";
import Image from "../Image";
const Logo = () => {
  return (
    <Link className={styles.customLogo} to="/">
      <Image
        src="/images/logo-light.svg"
        srcdark="/images/logo-dark.svg"
        alt="Bitdenex"
      />
      <Image
        src="/images/logo-light.svg"
        srcdark="/images/logo-dark.svg"
        alt="Bitdenex"
      />
    </Link>
  );
};

export default Logo;
