import cn from "classnames";
import styles from "./Main.module.sass";

const Main = ({ param, questionDetails }) => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h1", styles.title)}>{param?.slug?.toUpperCase()} Details</h1>
          <div className={styles.text}>
            {questionDetails?.description}
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/help-center@2x.png 2x"
            src={`${process.env.REACT_APP_IMAGE_URL}/${questionDetails?.banner}`}
            // src={"/images/content/png-banner.png"}
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
