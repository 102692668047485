import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {
  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h1", styles.title)}>
            Start Trading Today
          </h1>
          <div className={styles.text}>
            Invest with incredibly <strong>low trading fees</strong>
          </div>
          <p>We don’t charge complicated taker or maker fees. Our fees are simple, transparent and lowest in the Netherlands. We are aiming to apply the lowest trading fees in Europe and eventually in the full crypto space.</p>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/market-pic@2x.png 2x"
            src="/images/content/market-pic.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
