import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Legal Center
          </div>
          <h1 className={cn("h1", styles.title)}>Information about our service</h1>
          <div className={styles.text}>
            Here you can find all the important information about using our service. The terms and conditions and the privacy policy apply to anyone who visits the website of Bitdenex or otherwise makes use of the services offered by Bitdenex.
          </div>
          <div className={styles.text}>
            <span>Important Legal Note: </span> Bitdenex B.V. is officially registered. Bitdenex B.V., operator of Bitdenex.com and Bitdenex.nl, is registered with the Dutch Central Bank, De Nederlandsche Bank. You can find us in the open public crypto service provider register on their website.
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/legal/legal-main@2x.png 2x"
            src="/images/content/legal/legal-main.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
