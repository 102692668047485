import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./NewPassword.module.sass";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";

const NewPassword = ({ email, resetPasswordHandler, resetPasswordLoading }) => {
  // initializing the states and variables 
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const simpleValidator = useRef(new SimpleReactValidator({
    messages: {
      in: "New Password and Confirm password should be matched!",
    }
  }));
  const [, forceUpdate] = useState();

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    };
    setPasswordType("password");
  };

  return (
    <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
      <h3 className={cn("h3", styles.title)}>New password</h3>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="new password"
          name="new-password"
          type={passwordType}
          placeholder="Password"
          togglePassword={togglePassword}
          value={password}
          onChange={(e) => { setPassword(e.target.value); }}
          onBlur={() => { simpleValidator.current.showMessageFor("password") }}
          view
        />
        <span className={styles.danger}>{simpleValidator.current.message("password", password, "required|min:9")}</span>
        <TextInput
          className={styles.field}
          label="confirm password"
          name="confirm-password"
          type="password"
          placeholder="Password"
          // required
          value={confirmPassword}
          onChange={(e) => { setConfirmPassword(e.target.value); }}
          // view
          onBlur={() => { simpleValidator.current.showMessageFor("confirm-password") }}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("confirm password", confirmPassword, `required|in:${password}`)}
        </span>
      </div>
      <button
        disabled={resetPasswordLoading}
        className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            resetPasswordHandler(email, password, confirmPassword);
          }
          else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          };
        }}>
        Continue</button>
    </form>
  );
};

export default NewPassword;
