import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Verification.module.sass";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../../../components/Notification";
// import TextInput from "../../../components/TextInput";
import { resendOtp } from "../../../components/helper";

const Verification = ({ state, email, resetPasswordLoading, resetPasswordOtpGenerateHandler, resetPasswordOtpLoading, resetPasswordHandler, varifyOtp }) => {
  // initializing the states and variables 
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [thirdField, setThirdField] = useState("");
  const [fourthField, setFourthField] = useState("");
  const [fifthField, setFifthField] = useState("");
  const [sixthField, setSixthField] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [passwordType, setPasswordType] = useState("password");
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator({
    messages: {
      in: "Confirm password should be matched!",
    }
  }));
  // const togglePassword = (e) => {
  //   e.preventDefault();
  //   if (passwordType === "password") {
  //     setPasswordType("text");
  //     return;
  //   };
  //   setPasswordType("password");
  // };

  return (
    <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
      <h3 className={cn("h3", styles.title)}>Security verification</h3>
      <div className={styles.info}>
        To change your password, please complete the following verification.
      </div>
      <div className={styles.text}>
        Enter the 6 digit code received by <span>{state?.state?.email ? state?.state?.email : email}</span>
      </div>
      <div className={styles.code}>
        <div className={styles.number}>
          <input type="tel" name="field" maxLength="1" autoFocus ref={ref1} value={firstField}
            onChange={(e) => {
              if (e.target.value) {
                setFirstField(e.target.value);
                ref2.current.focus();
              }
              else if (e.target.value === '') {
                setFirstField('');
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", firstField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" maxLength="1" ref={ref2} value={secondField}
            onChange={(e) => {
              if (e.target.value) {
                setSecondField(e.target.value);
                ref3.current.focus();
              }
              else if (e.target.value === '') {
                setSecondField('');
                ref1.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", secondField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" maxLength="1" ref={ref3} value={thirdField}
            onChange={(e) => {
              if (e.target.value) {
                setThirdField(e.target.value);
                ref4.current.focus();
              }
              else if (e.target.value === '') {
                setThirdField('');
                ref2.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", thirdField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" maxLength="1" ref={ref4} value={fourthField}
            onChange={(e) => {
              if (e.target.value) {
                setFourthField(e.target.value);
                ref5.current.focus();
              }
              else if (e.target.value === '') {
                setFourthField('');
                ref3.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", fourthField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" maxLength="1" ref={ref5} value={fifthField}
            onChange={(e) => {
              if (e.target.value) {
                setFifthField(e.target.value);
                ref6.current.focus();
              }
              else if (e.target.value === '') {
                setFifthField('');
                ref4.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", fifthField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" maxLength="1" ref={ref6} value={sixthField}
            onChange={(e) => {
              if (e.target.value) {
                setSixthField(e.target.value);
              }
              else if (e.target.value === '') {
                setSixthField('');
                ref5.current.focus();
              }
            }} />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", sixthField, "required|numeric")}</span> */}
        </div>
      </div>

      {/* <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="new password"
          name="new-password"
          type={passwordType}
          placeholder="Password"
          // required
          value={password}
          onChange={(e) => { setPassword(e.target.value); }}
          onBlur={() => { simpleValidator.current.showMessageFor("password") }}
          view
          togglePassword={togglePassword}
        />
        <span className={styles.danger}>{simpleValidator.current.message("password", password, "required|min:8")}</span>
        <TextInput
          className={styles.field}
          label="confirm password"
          name="confirm-password"
          type="password"
          placeholder="Password"
          // required
          value={confirmPassword}
          onChange={(e) => { setConfirmPassword(e.target.value); }}
          // view
          onBlur={() => { simpleValidator.current.showMessageFor("confirm-password") }}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("confirm password", confirmPassword, `required|in:${password}`)}
        </span>
      </div> */}
      {/* <div className={styles.btns}> */}

      <button
        className={cn("button", styles.button)}
        disabled={resetPasswordLoading}
        onClick={(e) => {
          e.preventDefault();
          if (firstField !== "" && secondField !== "" && thirdField !== "" && fourthField !== "" && fifthField !== "" && sixthField !== "") {
            if (simpleValidator.current.allValid()) {
              varifyOtp(firstField, secondField, thirdField, fourthField, fifthField, sixthField, email);
            }
            else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            };
          }
          else {
            addNotification({
              title: 'Alert',
              message: "Please fill all the fields!",
              type: 'danger'
            });
          };
        }}>
        Continue
      </button>
      <button
        className={cn("button", styles.button)}
        disabled={resetPasswordOtpLoading}
        onClick={(e) => {
          e.preventDefault();
          resendOtp(state?.state?.email ? state?.state?.email : email, 'reset_password');
        }}>
        Resend code
      </button>
      {/* </div> */}

    </form>
  );
};

export default Verification;
