import cn from "classnames";
import styles from "./Player.module.sass";

const Player = ({ className, image, image2x }) => {
  return (
    <div className={cn(styles.player, className)}>
      <div className={styles.preview}>
        {/* <img src={image} alt="Video preview" /> */}
        <img srcSet={`${image2x} 2x`} src={image} alt="Item" />
      </div>
    </div>
  );
};

export default Player;
