import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = ({ scrollToRef, scrollToRefCatalog }) => {
  return (
    <div className={cn("buySection", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h1 className={cn("h1", styles.title)}>Buy & Sell Ripple/XRP</h1>
          <div className={styles.info}>Cryptocurrencies are changing the world.</div>
          {/* <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={() =>
                scrollToRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Learn now
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Main;
