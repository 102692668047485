import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Player from "../../../components/Player";

const breadcrumbs = [
  {
    title: "Legal Center",
    url: "/legal-center",
  },
  {
    title: "Privacy Policy",
  },
];

const Main = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h1 className={cn("h1", styles.title)}>
            Privacy Policy
          </h1>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
        <Player
          className={styles.player}
          image="/images/content/privacy/main.jpg"
          image2x="/images/content/privacy/main.jpg"
        />
      </div>
    </div>
  );
};

export default Main;
