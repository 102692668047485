import React, { useState } from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Dropdown from "../../../components/Dropdown";
import Card from "../Card";
// import { articles } from "../../../mocks/articles";
const dateOptions = ["Recently added", "Long added"];
const sortingOptions = ["Tutorial", "Usecase", "Trading", "Wallet"];

const articles = [
  {
    id: 1,
    title: `How to switch from the regional Bitdenex exchange to Bitdenex International via the Bitdenex App?`,
    url: "https://www.youtube.com/watch?v=sjKKkfLN9Yc",
    image: "/images/content/blog/crypto-saving-account.jpg",
    image2x: "/images/content/blog/crypto-saving-account.jpg",

  },
  {
    id: 2,
    title: `How to buy and sell crypto with Limit Order via the Bitdenex App?`,
    url: "https://www.youtube.com/watch?v=dgE4RrsqWLI",
    image: "/images/content/blog/crypto-saving-account.jpg",
    image2x: "/images/content/blog/crypto-saving-account.jpg",

  },
  {
    id: 3,
    title: `How to buy and sell crypto instantly via the Bitdenex App?`,
    url: "https://www.youtube.com/watch?v=3DnkYIt_B9A",
    image: "/images/content/blog/crypto-saving-account.jpg",
    image2x: "/images/content/blog/crypto-saving-account.jpg",

  },
  {
    id: 4,
    title: `How to withdraw Crypto via the Bitdenex App?`,
    url: "https://www.youtube.com/watch?v=5bH68cmAEKQ",
    image: "/images/content/blog/crypto-saving-account.jpg",
    image2x: "/images/content/blog/crypto-saving-account.jpg",

  },
  {
    id: 6,
    title: `How to register on bitdenex app?`,
    url: "https://www.youtube.com/watch?v=0p6hNFVW5sU",
    image: "/images/content/blog/crypto-saving-account.jpg",
    image2x: "/images/content/blog/crypto-saving-account.jpg",

  },
  {
    id: 7,
    title: `How to deposit Fiat via Bitdenex app?`,
    url: "https://www.youtube.com/watch?v=9VTseuxy9lI",
    image: "/images/content/blog/crypto-saving-account.jpg",
    image2x: "/images/content/blog/crypto-saving-account.jpg",

  },
]

const Catalog = ({ scrollToRefCatalog }) => {
  const [date, setDate] = useState(dateOptions[0]);
  const [sorting, setSorting] = useState(sortingOptions[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  // const blogs = articles.slice(3);
  const handleSubmit = (e) => {
    alert();
  };

  return (
    <div
      className={cn("section-padding section-mb0", styles.section)}
      ref={scrollToRefCatalog}
    >
      <div className={cn("container", styles.container)}>
        <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
          {/* <input
            className={styles.input}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="search"
            placeholder="Search anything about crypto"
            required
          />
          <button className={cn("button-circle", styles.result)}>
            <Icon name="search" size="24" />
          </button> */}
        </form>
        {/* <div className={styles.sorting}>
          <div className={styles.dropdown}>
            <Dropdown
              className={styles.dropdown}
              value={date}
              setValue={setDate}
              options={dateOptions}
            />
          </div>
          <div className={styles.nav}>
            {sortingOptions.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
          <div className={cn("tablet-show", styles.dropdown)}>
            <Dropdown
              className={styles.dropdown}
              value={sorting}
              setValue={setSorting}
              options={sortingOptions}
            />
          </div>
        </div> */}
        <div className={styles.list}>
          {articles.map((x, index) => (
            <Card className={styles.card} item={x} key={index} />
          ))}
        </div>
        {/* <div className={styles.btns}>
          <button className={cn("button-stroke button-small", styles.button)}>
            <span>Learn more</span>
            <Icon name="arrow-down" size="16" />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Catalog;
