import React from "react";
import cn from "classnames";
import styles from "./Popular.module.sass";
import Slider from "react-slick";
import Icon from "../Icon";
import Card from "./Card";

const items = [
  {
    title: "Developing more products and services",
    content: "Bitdenex will keep developing more services and products in the near future as per the needs and demands of the traders, and market innovations. ",
    button: "Get Started",
    image: "/images/content/card-pic-1.png",
    image2x: "/images/content/card-pic-1@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
  },
  {
    title: "Dedicated for the people",
    content: "We have designed our exchange for the common people. It is simple and easily useable for everyone. We don’t use complicated features. Simple is our standard.",
    button: "Get Started",
    image: "/images/content/card-pic-2.png",
    image2x: "/images/content/card-pic-2@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
  },
  {
    title: "Add more value to your portfolio",
    content: `Invest only in high valued cryptocurrencies. We add more value to 
    your trading portfolio by making only reliable and valuable coins 
    and tokens available on Bitdenex. `,
    button: "Get Started",
    image: "/images/content/card-pic-3.png",
    image2x: "/images/content/card-pic-3@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Popular = ({ classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
    ],
  };

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h3", styles.title)}>
            More than just <span>Low Trading Fees</span>
          </h2>
          <div className={styles.info}>
            Reasons why to trade and invest with us.
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className="popular-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Popular;
