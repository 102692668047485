import React, { useEffect, useState } from "react";
import Education from "./Education";
import Main from "./Main";
import requestHandler from "../../actions/httpClient";

const LegalCenter = () => {
  const [loading, setLoading] = useState(false);
  const [levelData, setLevelData] = useState([]);
  const [cashbackData, setCashbackkData] = useState([]);

  useEffect(() => {
    getLevelDataHandler();
  }, []);

  const getLevelDataHandler = async () => {
    setLoading(true);
    try {
      const getLevelPayload = await requestHandler("rewardsInfo", "post", "", "", "", "global");
      setLoading(false);
      setLevelData(getLevelPayload?.data?.data?.level_info);
      setCashbackkData(getLevelPayload?.data?.data?.referral_cashback);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Main
        levelData={levelData}
      />
      <Education
        loading={loading}
        levelData={levelData}
        cashbackData={cashbackData}
      />
    </>
  );
};

export default LegalCenter;
