import React, { useEffect, useState } from "react";
import Main from "./Main";
import Faq from "./Faq";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { useParams } from "react-router";

const HelpCenter = () => {

  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [questionDetails, setQuestionDetails] = useState([]);

  useEffect(() => {
    questionCoinHandler();
  }, []);

  const questionCoinHandler = async () => {
    setLoading(true);
    let data = {
      symbol: param.slug,
    };
    try {
      const questionCoinPayload = await requestHandler("getCoinQuestion", "post", data);
      setLoading(false);
      setQuestionData(questionCoinPayload?.data?.data?.questions);
      setQuestionDetails(questionCoinPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <Main param={param} questionDetails={questionDetails} />
      <Faq questionData={questionData} param={param} />
    </>
  );
};

export default HelpCenter;
