import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import ReactPlayer from 'react-player'

const Main = ({ scrollToRef, scrollToRefCatalog }) => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h1 className={cn("h1", styles.title)}>Blockchain & Crypto</h1>
          <div className={styles.info}>
            A Beginner’s Guide to Blockchain & Crypto
          </div>
          <div className={styles.btns}>
            <button
              className={cn("button-stroke", styles.button)}
              onClick={() =>
                scrollToRefCatalog.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              Video tutorial
            </button>
          </div>
        </div>
        <div className={styles.container}>
          <ReactPlayer url='https://www.youtube.com/watch?v=rIF3xmJDQPM'
            className={styles.main}
            width='1120px'
            height='655px'
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
