import cn from "classnames";
import styles from "./Main.module.sass";

const Main = ({ levelData }) => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h2", styles.title)}>
            Refer Your Friends & Earn Crypto Together.
          </h1>
          <div className={cn("stage", styles.stage)}>
            <h5 className={cn("h5", styles.title)}>
              You're just steps away from receiving your crypto commission rewards.
            </h5>
            Share your referral link with your friends, invite friends to sign up and earn up to {levelData[levelData?.length - 1]?.percentage}%
            commission on every Bitdenex Spot trade.
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/referral/referral-main@2x.png 2x"
            src="/images/content/referral/referral-main.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
