import React from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";

const Faq = ({ questionData, param }) => {
  const newOptions = [];

  questionData?.map((x) => {
    newOptions?.push({
      title: x.title,
      content: x.description,
    })
  });

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <h2 className={cn("h2", styles.title)}>Frequently asked questions about {param.slug}</h2>
        </div>
        <div className={styles.list}>
          {newOptions?.map((x, index) => (
            <Item
              className={styles.item}
              item={x}
              index={index}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
