import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";

const Main = () => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Crypto payments for your business
          </div>
          <h1 className={cn("h1", styles.title)}> Bitdenex Partners </h1>
          <div className={styles.text}>
            Bitdenex Pay is a contactless, borderless, and secure cryptocurrency payment system designed by Bitdenex. Make your business crypto-friendly and start accepting Bitcoin and other popular cryptocurrencies for sales made online and offline.
          </div>
         
          <a className={cn("button", styles.button)} href="https://docs.google.com/forms/d/e/1FAIpQLSe5lK_JhdFKbnMO8f0pa2MSHlLxiKguokMDVvuhrFfFzJZN0g/viewform?usp=sf_link" target="_blank">
            Become a Merchant
          </a>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/partners/main-partner@2x.png 2x"
            src="/images/content/partners/main-partner.png"
            alt="Cards"
          />
        </div>

      </div>

    </div>
  );
}; 

export default Main;
