import React, { useEffect, useRef, useState } from "react";
import Main from "./Main";
import Releases from "./Releases";
import Catalog from "./Catalog";
import CoinsAndTokens from "./CoinsAndTokens";
import News from "../../components/News";
import LoaderScreen from "../../components/LoaderScreen";
import requestHandler from "../../actions/httpClient";

const LearnCrypto = () => {
  const scrollToRef = useRef(null);
  const scrollToRefCatalog = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [coinData, setCoinData] = useState([]);

  const getCoinDetailsHandler = async () => {
    setLoading(true);
    try {
      const getCoinDetailsPayload = await requestHandler("getAllCoinQuestion", "post");
      setLoading(false);
      let filteredData = getCoinDetailsPayload?.data?.data?.filter((x) => {
        if (x?.status === 1) {
          return x;
        }
      })
      // setCoinData(getCoinDetailsPayload?.data?.data);
      setCoinData(filteredData);
    }
    catch (e) {
      setLoading(false);
    };
  };

  useEffect(() => {
    if (activeIndex === 1) {
      getCoinDetailsHandler();
    }
  }, [activeIndex]);

  return (
    <>
      {loading && <LoaderScreen />}
      <Main scrollToRef={scrollToRef} scrollToRefCatalog={scrollToRefCatalog} />
      <Releases
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        scrollToRef={scrollToRef}
        getCoinDetailsHandler={getCoinDetailsHandler}
      />
      {/* <Catalog
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        scrollToRefCatalog={scrollToRefCatalog}
      /> */}
      {activeIndex === 0 ?
        <Catalog
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          scrollToRefCatalog={scrollToRefCatalog}
        />
        :
        <CoinsAndTokens
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          scrollToRefCatalog={scrollToRefCatalog}
          coinData={coinData}
        />
      }
      <News classSection="section-bg section-mb0" />
    </>
  );
};

export default LearnCrypto;
