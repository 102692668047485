import React from "react";
import cn from "classnames";
import styles from "./Education.module.sass";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Levels from "../CommisionTable";
import CashbackTable from "../CashbackTable";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Education = ({ levelData, cashbackData }) => {

  const itemsOne = [
    {
      title: "Share your referral link with your friends",
      category: "purple",
      image: "/images/content/referral/referral-pic-1.png",
      image2x: "/images/content/referral/referral-pic-1@2x.png",
    },
    {
      title: "Invite your friends to sign up",
      category: "red",
      image: "/images/content/referral/referral-pic-2.png",
      image2x: "/images/content/referral/referral-pic-2@2x.png",
    },
    {
      title: `Earn up to ${levelData[levelData?.length - 1]?.percentage}% commission`,
      category: "green",
      image: "/images/content/referral/referral-pic-3.png",
      image2x: "/images/content/referral/referral-pic-3@2x.png",
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={cn("section", styles.education)}>
      <div className={cn("container", styles.container)}>
        <h3 className={styles.info}>
          <Link to="/terms-and-conditions-referral-program">Terms For Referral Program can be viewed here</Link>
        </h3>
        <h1 className={styles.info}>
          How To Refer Your Friends?
        </h1>

        <div className={cn("education-preview", styles.previewNew)}>
          <div className={styles.firstImage}>
            <h2 className={styles.bannerText}>Earn up to {levelData[levelData?.length - 1]?.percentage}% commission.</h2>
            {/* <p className={styles.info}>Refer friends to top up your holding and redeem 100 USDT!</p> */}
          </div>
          <div className={styles.secondImage}>
            <img
              srcSet={`/images/content/referral/referral-pic-4@2x.png 2x`}
              src={"/images/content/referral/referral-pic-4.png"}
              alt="refer"
            />
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider
            className={cn("education-slider", styles.slider)}
            {...settings}
          >
            {itemsOne.map((x, index) => (
              <div className={styles.item} key={index}>
                <div className={cn("education-preview", styles.preview)}>
                  <img
                    srcSet={`${x.image2x} 2x`}
                    src={x.image}
                    alt="refer"
                  />
                </div>
                <div className={styles.body}>
                  <div className={styles.title}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className={cn("container", styles.newContainer)}>
        <Levels
          levelData={levelData} />
      </div>

      <div className={cn("container", styles.newContainer)}>
        <CashbackTable
          cashbackData={cashbackData} />
      </div>

      <div className={cn("container", styles.newContainer)}>
        <h2 className={styles.infoNew}>
          Start Earning Today!
        </h2>
        <div className={styles.buttonClass}>
          <Link className={cn("button", styles.button)} to={localStorage.getItem('signature') ? 'exchange/BTC-EUR' : 'sign-up'}>
            Start Trading Now!
          </Link>
        </div>
      </div>
      <h3 className={styles.info}>
        <Link to="/terms-and-conditions-referral-program">Terms For Referral Program can be viewed here</Link>
      </h3>
    </div>
  );
};

export default Education;
