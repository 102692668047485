import React from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Card from "./Card";
import { articles } from "../../../mocks/articles";

const Catalog = ({ scrollToRefCatalog, coinData }) => {

  // const blogs = coinData.slice(3);

  return (
    <div
      className={cn("section-padding section-mb0", styles.section)}
      ref={scrollToRefCatalog}
    >
      <div className={cn("container", styles.container)}>
        <form className={styles.form} > </form>
        <div className={styles.list}>
          {coinData?.map((x, index) => (
            <Card className={styles.card} item={x} key={index} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Catalog;
