import cn from "classnames";
import styles from "./Panel.module.sass";
import { Link } from "react-router-dom";

const currency = [
  {
    title: "Every trade – Buy or Sell",
    heading: "Fast And Secure",
    p1: "- Your personal online account",
    p2: "- Secure backup of all your data",
    p3: "- 24/7 Live prices",
    p4: "- Free News and Blogs",
    image: "/images/logo.svg",
    url: "/sign-up",
    buttonText: "Register Now"
  },
  {
    title: "Every trade – Buy or Sell",
    heading: "Low (0.20%) Fees = More Profit",
    p1: "- Great trading platform",
    p2: "- Flexibility",
    p3: "- Instant buy and sell",
    p4: "- Security",
    image: "/images/logo.svg",
    url: "/sign-in",
    buttonText: "Get Started",
  },
];

const Panel = () => {

  return (
    <div className={styles.panel}>
      <div className={styles.body}>
        <div className={styles.list}>
          {currency.map((x, index) => (
            <div className={styles.item} key={index} to={x.url}>
              <div className={styles.icon}>
                <img src={x.image} alt="Currency" />
              </div>
              <div className={styles.details}>
                <div className={styles.line}>
                  <div className={styles.title}>{x.title}</div>
                </div>
                <div className={styles.price}>{x.heading}</div>
                <div className={styles.currency}>{x.p1}</div>
                <div className={styles.currency}>{x.p2}</div>
                <div className={styles.currency}>{x.p3}</div>
                <div className={styles.currency}>{x.p4}</div>
               <> <Link className={cn("button-small", styles.buttonNew)} to="/sign-up" >{x.buttonText}</Link></>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Panel;
