import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";
import { Link } from "react-router-dom";
import Play from "../../../../components/Play";

const Card = ({ className, item, index }) => {

  return (
    <Link className={cn(className, styles.card)} to={`/about/${item.symbol}`}>
      <div className={styles.preview}>
        {item.categoryText && (
          <div
            className={cn(
              "category",
              {
                "category-purple": item.category === "purple",
              },
              {
                "category-green": item.category === "green",
              },
              {
                "category-blue": item.category === "blue",
              },
              styles.category
            )}
          >
            {item.categoryText}
          </div>
        )}
        <div className={styles.imageContainer}>
          <img
            src="/images/content/newBannerWithBG.png"
            alt="Banner"
            className={styles.image}
          />
          <img
            src={process.env.REACT_APP_IMAGE_URL + "/" + item?.icon}
            alt="logo"
            className={styles.nestedImage}
          />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.avatar}>
          <img src={process.env.REACT_APP_IMAGE_URL + "/" + item?.icon} alt="logo" />
        </div>
        <div className={styles.details}>
          <div className={styles.title}>A Brief Guide to {item?.name?.toUpperCase()}</div>
          <div className={styles.author}>{item?.author}</div>
        </div>
        {item.statusText && (
          <div
            className={cn(
              {
                "category-stroke-green": item.status === "green",
              },
              styles.status
            )}
          >
            {item.statusText}
          </div>
        )}
      </div>
    </Link>
  );
};

export default Card;
