import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import { Link } from "react-router-dom";

const items = [
  {
    title: "Set your payment preferences",
    image2x: "/images/content/pay/pay-step-1@2x.png",
    image: "/images/content/pay/pay-step-1.png",
    content: "Payment Priority Order lets you choose which cryptocurrencies you want to spend, in the order you prefer.",
  },
  {
    title: "Lightning fast payments",
    image2x: "/images/content/pay/pay-step-2@2x.png",
    image: "/images/content/pay/pay-step-2.png",
    content: "Instantly send crypto to your friends and family worldwide.",
  },
  {
    title: "50+ cryptocurrencies to choose from",
    image2x: "/images/content/pay/pay-step-3@2x.png",
    image: "/images/content/pay/pay-step-3.png",
    content: "Send and spend your favourite cryptocurrencies with Bitdenex Pay.",
  },
  {
    title: "Earn money",
    image2x: "/images/content/pay/pay-step-4@2x.png",
    image: "/images/content/pay/pay-step-4.png",
    content: "Become smart trader to earn.",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h3", styles.title)}>Freedom to choose how you pay</h2>
          <div className={styles.info}>
            Bitdenex supports a variety of the most popular cryptocurrencies. Get the benefits of Bitdenex Pay and simple to use Exchange.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.preview}>
                <img
                  srcSet={`${x.image2x} 2x`}
                  src={x.image}
                  alt={`Step ${index + 1}`}
                />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </div>
          ))}
        </div>
        {/* <div className={styles.details}>
          <Link className={cn("button", styles.button)} to={localStorage.getItem('signature') ? 'exchange/BTC-EUR' : 'sign-up'}>
            Start Earning Today
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Steps;
