import React, { useEffect, useState } from "react";
import Login from "../../components/Login";
import Form from "./Form";
import Confirm from "./Confirm";
import Barcode from "./Barcode";
import Code from "./Code";
import { useNavigate } from "react-router";
import { addNotification } from "../../components/Notification";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import Logo from "../../components/Logo";

const SignUp = () => {
  // initializing the states and variables
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [userData, setUserData] = useState({});
  const [signUpSuccess, setSignUpSuccess] = useState([]);
  const [signUpTokenSuccess, setSignUpTokenSuccess] = useState([]);
  const [signInLoading, setSignInLoading] = useState(false);
  const [userSuccessData, setUserSuccessData] = useState([]);
  const [captchaData, setCaptchaData] = useState([])
  const [reCaptcha, setReCaptcha] = useState('')
  const [captchaLoading, setCaptchaLOading] = useState(false);
  const [options, setOptions] = useState([]);
  const [validEmail, setValidEmail] = useState(false);
  const [refferralCode, setReferralCode] = useState("");

  // API's data
  let signUpLoading = false
  let otpVerifyLoading = false
  let verify2FALoading = false

  // useEffect to check if the token/jwt_token is stored in the local storage
  useEffect(() => {
    getUserSignature();
    captchaHandler();
    getRegionData();
  }, []);

  const getRegionData = async () => {
    try {
      const regionPayload = await requestHandler("region", "post");
      const newArr = regionPayload?.data?.data?.map((x) => {
        return { "cId": x.id, "cValue": x.name }
      });
      setOptions([...newArr]);
    }
    catch (e) {
    };
  };

  const getUserSignature = async () => {
    if (localStorage.getItem('signature') && localStorage.getItem('signature') !== ""
      && localStorage.getItem('jwt_token') && localStorage.getItem('jwt_token') !== "") {
      navigate('/');
    }
    else {
      try {
        const signUpTokenPayload = await requestHandler("signuptoken", "get", null);
        setSignUpTokenSuccess(signUpTokenPayload);
      }
      catch (e) {
        window.location.href("/sign-up");
      }
    }
  };

  const captchaHandler = async () => {
    setCaptchaLOading(true)
    const data = {
      theme: localStorage.getItem('darkMode')
    }
    try {
      const captchPayload = await requestHandler("createCaptcha", 'post', data);
      setCaptchaData(captchPayload?.data)
      setCaptchaLOading(false)
    } catch (error) {
    }

  }

  const verify2FAHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    setSignInLoading(true)
    try {
      verify2FALoading = true
      let data = {
        email: userData.email,
        code: code,
      };
      const verify2FAPayload = await requestHandler("verifyLogin", "post", data);
      setSignInLoading(false)
      if (verify2FAPayload && verify2FAPayload.status === 200) {
        if (verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].signature) {
          var signature = verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].signature;
          signature = signature.replace('+', '_');
          signature = signature.replace('/', '-');
          window.location.href = verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].url + '?sig=' + signature;
        }
        else {
          window.location.href = verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].url;
        }
      }
    }
    catch (e) {
      setSignInLoading(false)
      verify2FALoading = false
      addNotification({
        title: 'Error',
        message: e.data.message[0].msg,
        type: 'danger'
      });
    }
  };

  // function to call the otp verification API
  const codeScreenHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    setSignInLoading(true)
    let data = {
      email_otp: code,
      email: userData.email,
      type: signUpSuccess.data.message.type,
      refferal_code: refferralCode,
    };
    try {
      otpVerifyLoading = true
      const codeScreenPayload = await requestHandler("verify_otp", "post", data);
      setSignInLoading(false)
      setUserSuccessData(codeScreenPayload?.data?.data);
      if (codeScreenPayload && codeScreenPayload.status === 200) {
        setActiveIndex(3);
      };
    } catch (e) {
      setSignInLoading(false)
      otpVerifyLoading = false
      addNotification({
        title: "Error",
        message: e.data.message[0].msg,
        type: "danger"
      });
    };
  };

  // function to get the all the data from sign up form
  const signUpHandler = async (email, password, policy, cId, regionValue, referralCode) => {
    setSignInLoading(true);
    let captchaData = {
      id: cId,
      code: reCaptcha
    };
    const userData = {};
    userData.policy = policy;
    userData.email = email;
    userData.password = password;
    userData.ciphertext = `${signUpTokenSuccess.data.data.value}${signUpTokenSuccess.data.data.id}`;
    userData.id = signUpTokenSuccess.data.data.id;
    userData.region = regionValue;
    userData.refferal_code = referralCode;
    setUserData(userData);
    try {
      const verifyCaptchaPayload = await requestHandler("verifyCaptcha", 'post', captchaData);
      setSignInLoading(false);
      if (verifyCaptchaPayload.status === 200 && validEmail) {
        setActiveIndex(1);
        setReCaptcha("");
        await captchaHandler();
      }
      else {
        addNotification({
          title: 'Alert',
          message: "Disposable email is not allowed.",
          type: 'danger'
        });
        setReCaptcha("");
        await captchaHandler();
      }
    } catch (e) {
      setSignInLoading(false);
      setReCaptcha("");
      await captchaHandler();
      addNotification({
        title: "Error",
        message: e.data.message[0].msg,
        type: "danger"
      });
    }
  };

  // function to call the sign up API
  const confirmHandler = async () => {
    try {
      setSignInLoading(true)
      signUpLoading = true
      const signUpPayload = await requestHandler("signup", 'post', userData);
      setReferralCode(signUpPayload?.data?.message?.data?.refferal_code);
      setSignUpSuccess(signUpPayload);
      if (signUpPayload && signUpPayload.status === 201) {
        setSignInLoading(false)
        setActiveIndex(2);
      }
    } catch (e) {
      setSignInLoading(false)
      signUpLoading = false
      addNotification({
        title: "Error",
        message: e.data.message[0].msg,
        type: "danger"
      });
    }
  };

  const setActiveIndexToZero = () => {
    setActiveIndex(0);
  };

  return (
    <Login
      content="Already have an account?"
      linkText="Login"
      linkUrl="/sign-in"
    >
      <Logo />
      {activeIndex === 0 &&
        <Form
          userData={userData}
          signUpHandler={signUpHandler}
          captcha={captchaData}
          reCaptcha={reCaptcha}
          setReCaptcha={setReCaptcha}
          captchaLoading={captchaLoading}
          options={options}
          setValidEmail={setValidEmail}
          validEmail={validEmail}
        />}
      {activeIndex === 1 &&
        <Confirm
          setActiveIndexToZero={setActiveIndexToZero}
          signUpLoading={signUpLoading}
          userData={userData}
          confirmHandler={confirmHandler}
        />}
      {activeIndex === 2 &&
        <Code
          otpVerifyLoading={otpVerifyLoading}
          codeScreenHandler={codeScreenHandler}
          userData={userData}
        />}
      {activeIndex === 3 &&
        <Barcode
          userSuccessData={userSuccessData}
          verify2FAHandler={verify2FAHandler}
        />
      }
      {signInLoading && <LoaderScreen />}
    </Login>
  );
};

export default SignUp;
