import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";

const Item = ({ className, item, index }) => {
  const [visible, setVisible] = useState(false);

  // const renderParagraphs = () => {
  //   return item?.content?.split('\n')?.map((paragraph, index) => (
  //     <p key={index}>{paragraph}</p>
  //   ));
  // };

  const renderParagraphs = () => {
    const content = item?.content;
    if (!content) return null;

    return content.split('\n').map((paragraph, index) => {
      const parts = paragraph.split(/(\bbitdenex\b)/i);

      return (
        <p key={index}>
          {parts.map((part, i) => {
            if (part.toLowerCase() === 'bitdenex') {
              return (
                <a key={i} href="https://bitdenex.com/market" target="_blank" rel="noreferrer">
                  {part}
                </a>
              );
            } else {
              return <>{part}</>
            }
          })}
        </p>
      );
    });
  };

  return (
    <div className={cn(className, styles.item)}>
      <div
        className={cn(styles.head, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <div className={styles.number}>
          {index < 9 && "0"}
          {index + 1}
        </div>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.arrow}>
          <Icon name="arrow-down" size="24" />
        </div>
      </div>
      <div className={cn(styles.body, { [styles.visible]: visible })}>
        {/* <p>{item?.content?.replace(/\n/g, '<br />')}</p> */}
        {/* {item?.content?.split(/\n+/)?.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))} */}
        {/* <div style={{ whiteSpace: 'pre-line' }}>
          {item?.content?.split('\n')?.map((paragraph, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </div> */}
        {renderParagraphs()}
      </div>
      {/* <p dangerouslySetInnerHTML={{ __html: {} }} /> */}

    </div>
  );
};

export default Item;
