import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Settings from "./Settings";
import Download from "./Download";
import Theme from "../Theme";
import User from "./User";
import Trade from "./Trade";
import BuyCrypto from "./BuyCrypto";
import Finance from "./Finance";

const postLoginNavigation = [
  {
    title: "Markets",
    url: "/market",
  }
];

const preLoginNavigation = [
  {
    title: "Markets",
    url: "/market",
  },
  {
    title: "NFT",
    // url: "https://nft.bitdenex.com/",
  },
];

const Header = ({ headerWide }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const navigation = localStorage.getItem("signature") ? postLoginNavigation : preLoginNavigation;

  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.picDesktop}
            src="/images/logo-light.svg"
            srcdark="/images/logo-dark.svg"
            alt="Bitdenex"
          />
          <img
            className={styles.picMobile}
            src="/images/logo.svg"
            alt="Bitdenex"
          />
        </Link>
        <div className={styles.wrapper}>
          <div className={cn(styles.wrap, { [styles.visible]: visibleNav })}>
            <nav className={styles.nav}>
              <Finance className={styles.dropdown} />
              {navigation.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) :
                  x.title.toLowerCase() === "nft" ?
                    (
                      <a
                        className={styles.item}
                        activeClassName={styles.active}
                        onClick={() => setVisibleNav(false)}
                        href={"https://nft.bitdenex.com/"}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {x.title}
                      </a>
                    )
                    :
                    (
                      <NavLink
                        className={styles.item}
                        activeClassName={styles.active}
                        onClick={() => setVisibleNav(false)}
                        to={x.url}
                        key={index}
                      >
                        {x.title}
                      </NavLink>
                    )
              )}
              {localStorage.getItem("signature") && (
                <>
                  <BuyCrypto className={styles.dropdown} />
                  <Trade className={styles.dropdown} />
                </>
              )}
            </nav>
          </div>
          <div className={styles.btns}>
            {localStorage.getItem("signature") ?
              <>
                <Settings className={styles.settings} />

                <div className={styles.control}>
                  <NavLink
                    className={cn("button-stroke button-small", styles.button)}
                    activeClassName={styles.active}
                    to="/open-orders-list"
                  >
                    Orders
                  </NavLink>
                  <NavLink
                    className={cn("button-stroke button-small", styles.button)}
                    activeClassName={styles.active}
                    to="/wallet-overview"
                  >
                    Wallet
                  </NavLink>
                  <Theme className={styles.theme} icon />
                  <User className={styles.user} />
                </div>
              </>
              :
              <>
                <NavLink
                  className={cn("button-small", styles.button)}
                  activeClassName={styles.active}
                  to="/sign-up"
                  onClick={() => setVisibleNav(false)}
                >
                  Sign Up
                </NavLink>
                <NavLink
                  className={cn("button-stroke button-small", styles.button)}
                  activeClassName={styles.active}
                  to="/sign-in"
                  onClick={() => setVisibleNav(false)}
                >
                  Login
                </NavLink>

              </>
            }
            <Download className={styles.download} />

          </div>
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
