import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Confirm.module.sass";
import Icon from "../../../components/Icon";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../../../components/Notification";

const Confirm = ({ verify2FAHandler, verify2FALoading }) => {
  // initializing the states and variables 
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [thirdField, setThirdField] = useState("");
  const [fourthField, setFourthField] = useState("");
  const [fifthField, setFifthField] = useState("");
  const [sixthField, setSixthField] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator())

  return (
    <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
      <h3 className={cn("h3", styles.title)}>Enter your 2FA code.</h3>

      <div className={styles.info}>
        Help us to secure your account. <br></br>Please complete the verifications below
      </div>
      <div className={styles.info}>
        Enter the six-digit code provided by the Google Authenticator.
      </div>

      <div className={styles.code}>
        <div className={styles.number}>
          <input autoFocus name="field" ref={ref1} maxLength="1" type="tel" value={firstField} onChange={(e) => {
            if (e.target.value) {
              setFirstField(e.target.value);
              ref2.current.focus();
            }
            else if (e.target.value === '') {
              setFirstField('');
            }
          }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", firstField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" name="field" ref={ref2} maxLength="1" value={secondField} onChange={(e) => {
            if (e.target.value) {
              setSecondField(e.target.value);
              ref3.current.focus();
            }
            else if (e.target.value === '') {
              setSecondField('');
              ref1.current.focus();
            }
          }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", secondField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" name="field" ref={ref3} maxLength="1" value={thirdField} onChange={(e) => {
            if (e.target.value) {
              setThirdField(e.target.value);
              ref4.current.focus();
            }
            else if (e.target.value === '') {
              setThirdField('');
              ref2.current.focus();
            }
          }} />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", thirdField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" name="field" ref={ref4} maxLength="1" value={fourthField} onChange={(e) => {
            if (e.target.value) {
              setFourthField(e.target.value);
              ref5.current.focus();
            }
            else if (e.target.value === '') {
              setFourthField('');
              ref3.current.focus();
            }
          }} />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", fourthField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" name="field" ref={ref5} maxLength="1" value={fifthField} onChange={(e) => {
            if (e.target.value) {
              setFifthField(e.target.value);
              ref6.current.focus();
            }
            else if (e.target.value === '') {
              setFifthField('');
              ref4.current.focus();
            }
          }} />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", fifthField, "required|numeric")}</span> */}
        </div>
        <div className={styles.number}>
          <input type="tel" name="field" maxLength="1" ref={ref6} value={sixthField} onChange={(e) => {
            if (e.target.value) {
              setSixthField(e.target.value);
            }
            else if (e.target.value === '') {
              setSixthField('');
              ref5.current.focus();
            }
          }} />
          {/* <span className={styles.danger}>{simpleValidator.current.message("field", sixthField, "required|numeric")}</span> */}
        </div>
      </div>
      <button className={cn("button", styles.button)}
        disabled={verify2FALoading}
        onClick={(e) => {
          e.preventDefault();
          if (firstField !== "" && secondField !== "" && thirdField !== "" && fourthField !== "" && fifthField !== "" && sixthField !== "") {
            // if (simpleValidator.current.allValid()) {
            verify2FAHandler(firstField, secondField, thirdField, fourthField, fifthField, sixthField);
          }
          else {
            addNotification({
              title: 'Alert',
              message: "Please enter a valid otp.",
              type: 'danger'
            });
            // simpleValidator.current.showMessages();
            // forceUpdate(1);
          }
        }
        }>
        <span>Continue</span>
        <Icon name="arrow-right" size="16" />
      </button>
      <div className={styles.infoText}>
        Unable to access your authentication code? <br></br>Please raise ticket at <span className={styles.correct}>support@bitdenex.com</span>
      </div>
    </form >
  );
};

export default Confirm;
