import React from "react";
import cn from "classnames";
import styles from "./Pay.module.sass";
import Slider from "react-slick";
import Image from "../Image";
import Icon from "../Icon";
import Card from "./Card";

const items = [
  {
    title: "Send and spend crypto at ZERO fees",
    content: "Say goodbye to unfair fees and charges. Pay, send and receive crypto—all with zero fees.",
    stage: "Zero fees",
    image: "/images/content/zero-fee.png",
    image2x: "/images/content/zero-fee@2x.png",
    url: localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up",
    buttonText: "Start Trading"
  },
  {
    title: "Shop and pay with crypto",
    content: "Spend crypto at stores and businesses that support Bitdenex Pay.",
    stage: "Shop with crypto",
    image: "/images/content/shop.png",
    image2x: "/images/content/shop@2x.png",
    url: localStorage.getItem("signature") ? "/market" : "/sign-up",
    buttonText: "View Coins"
  },
  {
    title: "Send crypto to friends and family",
    content:"Bitdenex Pay is a fast and safe way to send crypto to your friends and family, all with a single tap.",
    stage: "Send crypto",
    image: "/images/content/send-crypto.png",
    image2x: "/images/content/send-crypto@2x.png",
    url: localStorage.getItem("signature") ? "/buy-crypto" : "/sign-up",
    buttonText: "Buy Now"
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Pay = ({ classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="18" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="18" />
      </SlickArrow>
    ),
  };

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          {/* <div className={styles.logo}>
            <Image
              className={styles.pic}
              src="/images/logo-light.svg"
              srcdark="/images/logo-dark.svg"
              alt="BitCloud"
            />
          </div> */}
          <h2 className={cn("h3", styles.title)}>
              Introducing <span> Bitdenex Pay </span> 
          </h2>
          {/* <div className={styles.info}>
            A creative exchange that lead and inspire
          </div> */}
        </div>
        <div className={styles.wrapper}>
          <Slider className="news-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Pay;
