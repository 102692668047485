import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({
  item,
  index
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} >
          <div className={styles.col}>
            {index + 1}
          </div>
          <div className={styles.col}>
            {`${parseInt(item?.min).toLocaleString()}-${parseInt(item?.max).toLocaleString()}`}
          </div>
          <div className={styles.col}>
            {item?.max_leverage}
          </div>
          <div className={styles.col}>
            {item?.maintenance_percentage}
          </div>
          <div className={styles.col}>
            {item?.hourly_interest}
          </div>
          <div className={styles.col}>
            {item?.maintenance_amount}
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
